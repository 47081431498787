// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-carnivalcorp-js": () => import("./../../../src/pages/carnivalcorp.js" /* webpackChunkName: "component---src-pages-carnivalcorp-js" */),
  "component---src-pages-company-wallbox-js": () => import("./../../../src/pages/company/wallbox.js" /* webpackChunkName: "component---src-pages-company-wallbox-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-get-app-js": () => import("./../../../src/pages/get-app.js" /* webpackChunkName: "component---src-pages-get-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-confirmation-js": () => import("./../../../src/pages/user-confirmation.js" /* webpackChunkName: "component---src-pages-user-confirmation-js" */),
  "component---src-pages-wework-js": () => import("./../../../src/pages/wework.js" /* webpackChunkName: "component---src-pages-wework-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

