/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const device = require("react-device-detect");

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};

exports.onClientEntry = () => {
  window.onload = () => {
    // LOAD RECAPTCHA ONLY IF WE ARE ON A BROWSER
    if (device.isBrowser) {
      const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_KEY;

      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      document.body.appendChild(script);
    }
  };
};
